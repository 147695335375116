import React from 'react'
import { Link } from "gatsby"
import DoubleLineArrow from "../../../static/images/indexPageImages/double-line-arrow.svg"

const ReadMoreLink = ({ linkText, toPage }) => {
    return (
        <Link to={toPage} className="read-more-link">
            <span>{linkText}</span>
            <img src={DoubleLineArrow} alt="double-line-arrow"/>
        </Link>
    )
}

export default ReadMoreLink