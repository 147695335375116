import React from 'react'
import { Link, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import "../../../static/styles/main.scss"
import featuredImage from "../../../static/images/commonImg/social-card-only-logo.png"
import Seo from "../../components/seo"
import Header from "../../components/internationalComponents/header"
import Footer from "../../components/internationalComponents/footer"
import ReadMoreLink from "../../components/indexComponents/readMoreLink"
import IntForm from "../../components/internationalComponents/intForm"
// Hero
import IntHeroText from "../../../static/images/intPage/svgImportedAsComponent/int-hero-text.svg"
// Profile
import InstaLogo from "../../../static/images/commonImg/instagram.svg"
import AmazonLogo from "../../../static/images/commonImg/amazon-radius.svg"
// Explain
import ProcessDesktopInt from "../../../static/images/intPage/process-desktop-int.svg"
import ProcessMobileInt from "../../../static/images/intPage/process-mobile-int.svg"
import { useWindowDimension } from "../../hooks/useWindowDimension"
// Blog
import SvgImgSelector from "../../components/svgImgSelector"
import { blogBGColor } from "../../utils/variables"

const InternationalIndex = ({ data }) => {
    const intKeywords = ["jamstack", "japan", "Tokyo", "gatsby.js", "english speaking", "headless cms", "website", "web design"]
    return (
        <>
            <Seo title="Web Development Agency in Tokyo - Jamstack & Headless CMS" 
                 description="monotein is the Tokyo-based web development business, run by a Japanese developer, Aki. We're specialised in a development of Jamstack, static site generator, Gatsby, Next.js and Headless CMS."
                 keywords={intKeywords}
                 featuredImage={featuredImage}
            />
            <Header page="International"/>
            <div className="int-root">
                <div className="container">
                    <Hero />
                    <Profile />
                    <Explain />
                    {/* <Works data={data} /> */}
                    <Blogs />
                    <IntForm/>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default InternationalIndex

const Hero = () => {
    return (
        <div className="intHero-root">
            <IntHeroText/>
            <h1 style={{display: "none"}}>We build a website that converts visitors into your customers</h1>
            <p className="jamhead-text"><span>― </span>Jamstack & Headless CMS<span> ―</span></p>
        </div>
    )
}

const Profile = () => {
    return (
        <div className="intProfile-root">
            <h2 className="monotein">About monotein</h2>
            <div className="img-text-container">
                <div className="img-container">
                    <StaticImage src="../../static/images/picMyself/writer-pic-200x200.png" alt="Profile Pic" className="profile-img" placeholder="blurred" />
                </div>
                <div className="text-container">
                    <p>monotein is a web service provider in Tokyo, specialised in high-performant, result-driven websites, run by Aki. Aki has lived in several foreign countries and has a vast and deep-understanding in web development as well as web business.</p>
                    <hr/>
                    <p>Aki is keen on sharing his knowledge with fellow developers and has so far written <a href="/cu3tfwv45sr9jvei9nm2/?r=amazon-writer-central=int" rel="noopener noreferrer" target="_blank" style={{textDecoration: "underline"}}>8 successful books</a> about web development.</p>
{/*                     <a style={{marginRight: "20px"}} href="/cu3tfwv45sr9jvei9nm2/?r=ig-business=int" rel="noopener noreferrer" target="_blank"><img src={InstaLogo} alt="instagram" className="insta-logo"/></a> */}
                    <a href="/cu3tfwv45sr9jvei9nm2/?r=amazon-writer-central=int" rel="noopener noreferrer" target="_blank"><img src={AmazonLogo} alt="instagram" className="insta-logo"/></a>
                </div>
            </div>
        </div>
    )
}

const Explain = () => {
    const { width } = useWindowDimension()
    return (
        <div className="intExplain-root">
            {720 < width && <img src={ProcessDesktopInt} alt="process1" style={{width: "100%", height: "auto", margin: "30px auto 60px"}}/>}
            {720 > width && <img src={ProcessMobileInt} alt="process1" style={{width: "100%", height: "auto", margin: "0px auto 30px"}}/>}
        </div>
    )
}

const Works = ({ data }) => {
    const worksData = [
        {
            id: 0,
            name: "Company Website",
            tech: ["Gatsby", "/ React",  "/ Prismic CMS", "/ SCSS", "/ Netlify"],
            url: "/cu3tfwv45sr9jvei9nm2/?r=zetaliteral=int",
            //img: "../../../static/images/worksPage/worksImg1.png",
        },
        {   
            id: 2,
            name: "Company Website",
            tech: ["Gatsby", "/ React", "/ Contentful", "/ SCSS", "/ Netlify"],
            url: "/cu3tfwv45sr9jvei9nm2/?r=moonagefilms=int",
            //img: "../../../static/images/worksPage/worksImg3.png",
        },
        {   
            id: 5,
            name: "Event Website",
            tech: ["Gatsby", "/ React", "/ SCSS", "/ Netlify"],
            url: "/cu3tfwv45sr9jvei9nm2/?r=yukiomishima=int",
            //img: "../../../static/images/worksPage/worksImg6.png",
        },
    ]
    return (
        <div className="intWorks-root">
            <h2 className="monotein">Works</h2>
            <div className="new-grid-container fr3">
                {worksData.map((x, index) => {
                    const { id, name, url } = x
                    const workImg = getImage(data.allImageSharp.edges[id].node.gatsbyImageData)
                    return(
                        <div key={index}>
                            {console.log(data)}
                            <a href={url} rel="noopener noreferrer" target="_blank"><GatsbyImage image={workImg} alt="work"/></a>
                            <h4>{name}</h4>
                        </div>
                    )
                })}
            </div>
            <ReadMoreLink linkText="More" toPage="/works/?r=int"/>
        </div>
    )
}

const Blogs = () => {
    const blogData = [
        { title: "Why Slow Website is Going to Fail?", path: "/blog/why-website-has-to-be-fast/?r=int" },
        { title: "How to Bring Results from Your Website", path: "/blog/how-to-make-results-from-website/?r=int" },
        { title: "【WordPress vs. Jamstack】 Which to Use for Successful Website", path: "/blog/wordpress-and-jamstack/?r=int" },
    ]
    return (
        <div className="intBlogs-root">
        <h2 className="monotein">Blogs</h2>
            <div className="new-grid-container fr3">
                {blogData.map((x, index) => {
                    const { title, path } = x
                    return(
                        <div className="blog-card" key={index} style={{backgroundColor: blogBGColor[index.toString().slice(-1).replace("9", "0")]}}>
                            <Link to={path}>    
                                <SvgImgSelector index={index}/>
                                <h3>{title}</h3>
                            </Link> 
                        </div>
                    )
                })}
            </div>
        <ReadMoreLink linkText="More" toPage="/blog/?r=int"/>
        </div>
    )
}

export const intQuery = graphql`
        query IntQuery {
            allImageSharp(
                filter: {original: {src: {regex: "/(worksImg)/"}}}
                sort: {order: ASC, fields: original___src}
            ) {
                edges {
                    node {
                        id
                        gatsbyImageData(
                            placeholder: BLURRED, 
                            quality: 90,
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        }
`